<template>
  <div class="bg">
    <div class="box">
      <div class="content">
        <div class="logo">
          <img src="../../images/mini-logo.png">
        </div>
        <div class="title">
          <div class="max-title">国家工业互联网认证解析体系(北京)顶级节点</div>
          <div class="min-title">First level node of national industrial Internet identification analysis system (Beijing)</div>
        </div>
        <div class="title">
          <div class="max-title">中国工业互联网认证服务中心</div>
          <div class="min-title">China Industrial Internet identification service center</div>
        </div>
        <div class="main-title">
          <img src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_7de51270-175b-11ec-b956-afc30e36f2fa/fed949f3-a094-4877-806f-be8ff83c26d3.png">
        </div>
        <div class="desc">国家工业互联网认证解析体系企业识别码<span>{{ companyNode }}</span>已由<span>{{ entBasicInfo.orgName }}</span>注册，并已在国家工业互联网认证解析体系顶级节点中备案。</div>
        <div class="flex company">
          <div class="left"><img :src="qrCode"></div>
          <div class="right">
            <div>企业名称：{{ entBasicInfo.orgName }}</div>
            <div>企业认证码：{{ companyNode }}</div>
            <div>注册日期：{{ registerTime }}</div>
          </div>
          <img class="seal-img" src="https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_c433ed20-eb93-11ec-b8b1-33d46d2ddef5/b36023c9-9441-485c-bf0c-216cd3db7a6a.png">
        </div>
        <div class="tip">注册服务机构：工业互联网认证解析国家顶级节点--北京</div>
        <div class="mall-tip">以下说明与本证书主文一起构成本证书统一整体，不可分割: 1.本证书上列出的企业是所列企业识别码的合法持有人，依法享有该企业识别码项下之各项权利。2.本证书中所列企业识别码之注册或使用而可能引发的与任何第三方之纠纷或冲突，均由该企业识别码注册企业本人承担，企业识别码所属注册二级节点不承担任何法律责任。企业识别码所属注册二级节点亦不在此类纠纷或冲突中充当证人、调停人或其它形式之参与人。3.本证书不得用于非法目的，企业识别码所属注册二级节点不承担任何由此而发生或可能发生之法律责任。</div>
      </div>
    </div>
    <div class="download" @click="download">下载证书</div>
  </div>
</template>

<script>
import { companyApi } from "@/services/api";
import config from "@/config/config";
import { downloadIamge } from "@/utils/downloadImage";
import { formatDate } from "@/utils/time";
export default {
  data() {
    return {
      qrCode: ""
    };
  },
  computed: {
    entBasicInfo() {
      return this.$store.state.entBasicInfo;
    },
    companyNode() {
      return this.$store.state.company_node;
    },
    companyMpkey() {
      return this.$store.state.mpkey;
    },
    registerTime() {
      return formatDate(this.$store.state.registerTime, "yyyy年MM月dd日");
    }
  },
  mounted() {
    this.getQrCode();
  },
  methods: {
    changeStyle(key) {
      this.styleKey = key;
      this.style.backgroundImage = `url('${this.styleList[key]}')`;
    },
    async getQrCode() {
      let { data } = await companyApi.getQrCode({ content: `${config.webSiteHttp}record?nodeId=${this.companyNode}` });
      this.qrCode = data;
    },
    async download() {
      let { data } = await companyApi.downloadImag({ type: "certificate" });
      downloadIamge(data, "入网证书");
    }
  }
};
</script>

<style lang="less" scoped>
.bg{
  background-color: #ffffff;
  padding: 60px 0 0 157px ;
}
.flex{
  display: flex;
}
.box{
  width: 750px;
   padding: 39px 27px;
  background: #F3F9FF;
  .content{
    background: url('https://assets-service.obs.cn-south-1.myhuaweicloud.com/test/mp_642b1820-9e79-11ec-b125-0bb52bbb7840/97d5a06c-c229-4502-9fbf-59e9d9a19348.png') no-repeat;
    background-size: cover;
    width: 564px;
    height: 832px;
    padding: 81px 90px;
    .logo{
      text-align: center;
      img{
        width: 61px;
      height: 61px;
      }
    }
    .title{
      margin-top: 20px;
      text-align: center;
      .max-title{
        color: #222222;
        font-size: 25px;
        font-weight: bold;
      }
      .min-title{
        font-size: 12px;
        color: #7B7E8C;
      }
    }
    .main-title{
      text-align: center;
      margin-top: 56px;
      img{
        width: 497px;
        height: 60px;
      }
    }
    .desc{
      height: 156px;
      margin-top: 56px;
      color: #222222;
      font-size: 21px;
      font-weight: 500;
      line-height: 42px;
      font-family: Source Han Sans SC;
      span{
        color: #1285D6;
      }
    }
    .company{
      color: #222222;
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      align-items: center;
      position: relative;
      .left{
        width: 128px;
        height: 128px;
        background: #fff;
        margin-right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 121px;
          height: 121px;
        }
      }
      .right{
        height: 100px;
        border-left: 2px solid #222222;;
        padding-left: 23px;
        z-index: 1;
        div{
          margin-bottom: 15px;
        }
      }
    }
    .tip{
      font-size: 16px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      line-height: 42px;
      color: #222222;
      margin-top: 25px;
      text-align: center;
    }
  }
}
.seal-img{
  position: absolute;
  right: 90px;
  width: 106px;
  height: 106px;
  z-index: 0;
}
.mall-tip{
  font-size: 14px;
  color: #7B7E8C;
  font-weight: 400;
  line-height: 18px;
  font-family: Microsoft YaHei;
  margin-top: 3px;
}
.download{
  margin-top: 26px;
  width: 120px;
  height: 36px;
  background: #1285D6;
  opacity: 1;
  border-radius: 2px;
  text-align: center;
  line-height: 36px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 350px;
}
</style>